export const useMarkup = (title: string, description: string) => {
  const route = useRoute();

  const schema = ref({
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    headline: title,
    name: title,
    mainEntity: 'https://swop.is',
    url: `https://swop.is${route.path}`,
    description,
    about: description,
    datePublished: '2021-08-01',
    dateModified: '2022-10-01',
    image: 'https://swop.is/favicons/ogImage.png',
    publisher: {
      '@type': 'Organization',
      name: 'Swop',
      url: 'https://swop.is',
      email: 'support@swop.is',
      logo: {
        '@type': 'ImageObject',
        url: 'https://swop.is/favicons/ogImage.png',
      },
    },
  });

  useHead({
    title,
    meta: [
      {
        name: 'description',
        content: description,
      },
      {
        name: 'og:title',
        content: title,
      },
      {
        name: 'og:description',
        content: description,
      },
      {
        name: 'og:url',
        content: `https://swop.is${route.path}`,
      },
      {
        name: 'twitter:title',
        content: title,
      },
      {
        name: 'twitter:description',
        content: description,
      },
    ],
    link: [
      {
        rel: 'canonical',
        href: `https://swop.is${route.path}`,
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(schema.value),
      },
    ],
  });
};
